import React from "react"
import { FormGroup, Input, InputGroup, InputGroupAddon } from "reactstrap"
import CustomButton from "./CustomButton"

type ButtonProps = {
  onClick: () => void
  content: string | JSX.Element
}

type Option = {
  key: string
  text: string
}
interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  button?: ButtonProps
  placeholder?: string
  label: string
  type?: "text" | "textarea" | "select"
  inputClass?: string
  options?: Option[]
  formElement?: boolean
}

const CustomInput = ({
  button,
  label,
  placeholder = "",
  type = "text",
  inputClass,
  options,
  formElement,
  value,
  onChange,
}: CustomInputProps) => {
  let inputClassName = "CustomInput__input"
  if (button) inputClassName += " input--btn_append"
  if (inputClass) inputClassName += ` ${inputClass}`

  let inputElement = null
  if (type === "select") {
    inputElement = (
      <Input
        placeholder={placeholder}
        type={type}
        className={inputClassName}
        value={value}
        onChange={onChange}
      >
        {options.map(({ key, text }) => {
          return (
            <option key={key} value={key}>
              {text}
            </option>
          )
        })}
      </Input>
    )
  } else {
    inputElement = (
      <Input
        placeholder={placeholder}
        type={type}
        className={inputClassName}
        value={value}
        onChange={onChange}
      />
    )
  }

  const input = (
    <>
      <label className="CustomInput__label">{label}</label>
      {inputElement}
      {button && (
        <InputGroupAddon addonType="append">
          <CustomButton
            className="CustomInput__button"
            arrow
            onClick={button.onClick}
          >
            {button.content}
          </CustomButton>
        </InputGroupAddon>
      )}
    </>
  )

  if (formElement) {
    return <FormGroup className="CustomInput">{input}</FormGroup>
  }
  return <InputGroup className="CustomInput">{input}</InputGroup>
}

export default CustomInput
