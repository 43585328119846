import React, { ChangeEvent, useState } from "react"
import { Col, Input, Row } from "reactstrap"
import SectionHeader from "../SectionHeader"
import CustomInput from "../CustomInput"
import ReactHtmlParser from "react-html-parser"
import SuccessIcon from "../../icons/success_arrow.svg"

const Newsletter = ({ translationData }) => {
  const [email, setEmail] = useState("")
  const [isError, setError] = useState(false)
  const [isSuccess, setSuccess] = useState(false)

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleNewsletterRequest = () => {
    fetch(process.env.GATSBY_NEWSLETTER_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then(resp => {
        if (resp.status === 200) setSuccess(true)
        else {
          setError(true)
        }
      })
      .catch(err => {
        setError(true)
      })
  }

  const sendNewsletterRequest = () => {
    setSuccess(false)
    setError(false)
    handleNewsletterRequest()
  }

  return (
    <Row className="Newsletter g-0">
      <Col
        md={{
          size: 12,
          offset: 0,
        }}
        lg={{
          offset: 1,
          size: 5,
        }}
      >
        <div className="d-flex flex-wrap justify-content-center align-items-center h-100">
          <div className="Newsletter__text-container">
            <SectionHeader
              title={translationData.title}
              description={translationData.subTitle}
            />
            <div className="Newsletter__description">
              {ReactHtmlParser(translationData.description.html)}
            </div>
          </div>
        </div>
      </Col>
      <Col
        sm={12}
        md={{
          offset: 1,
          size: 10,
        }}
        lg={{
          offset: 0,
          size: 5,
        }}
      >
        <div className="d-flex flex-wrap align-items-center h-100">
          <div className="Newsletter__input_container">
            <CustomInput
              inputClass="Newsletter__input_container__input"
              label={translationData.inputElement.label}
              placeholder={translationData.inputElement.placeholder}
              button={{
                onClick: sendNewsletterRequest,
                content: translationData.inputElement.buttonText,
              }}
              onChange={onEmailChange}
              value={email}
            />

            <div className="Newsletter__result d-flex">
              {isSuccess && (
                <div className="Newsletter__result--success">
                  <SuccessIcon />
                  <div className="ml-2">{translationData.successText}</div>
                </div>
              )}
              {isError && (
                <div className="Newsletter__result--error">
                  {translationData.errorText}
                </div>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Newsletter
