import React from "react"
import Image from "./Image"
import ReactHtmlParser from "react-html-parser"

type HeroHeaderProps = {
  imageUrl: string
  title: string
  description
  backgroundUrl: string
}

const HeroHeader = ({
  imageUrl,
  backgroundUrl,
  description,
  title,
}: HeroHeaderProps) => {
  return (
    <div className="HeroHeader">
      <div className="HeroHeader__mobile-content">
        <h1 className="HeroHeader__content__title">{title}</h1>
        <div className="HeroHeader__content__description">
          {ReactHtmlParser(description.html)}
        </div>
        <div className="HeroHeader__images">
          <img src={backgroundUrl} className="HeroHeader__images__background" />
          <div className="HeroHeader__images__asset-wrapper">
            <div className="HeroHeader__images__asset-wrapper__asset">
              <Image src={imageUrl} />
            </div>
          </div>
        </div>
      </div>
      <img src={backgroundUrl} className="HeroHeader__background" />
      <div className="HeroHeader__content">
        <h1 className="HeroHeader__content__title">{title}</h1>
        <div className="HeroHeader__content__description">
          {ReactHtmlParser(description.html)}
        </div>
      </div>
      <div className="HeroHeader__asset">
        <Image src={imageUrl} />
      </div>
    </div>
  )
}

export default HeroHeader
