import React from "react"
import { Button } from "reactstrap"

import Arrow from "../icons/arrow_white_right.svg"

interface CustomButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  arrow?: boolean
}

const CustomButton = ({
  children,
  arrow,
  className,
  ...props
}: CustomButtonProps) => {
  let buttonClassName = "CustomButton"
  if (className) buttonClassName += ` ${className}`

  return (
    <Button color="primary" {...props} className={buttonClassName}>
      {children}
      {arrow && <Arrow />}
    </Button>
  )
}

export default CustomButton
