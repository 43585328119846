import React from "react"
import Newsletter from "../components/sections/Newsletter"
import { SectionTypes } from "./constants"

function footerRender(translationObject) {
  return (
    translationObject.footerSections?.map((footerSection, index) => {
      switch (footerSection.type) {
        case SectionTypes.Newsletter:
          const newsletterTranslation = footerSection.newsletter

          return (
            <Newsletter
              key={`footer-section-${index}`}
              translationData={newsletterTranslation}
            />
          )
      }
    }) ?? null
  )
}

export default footerRender
