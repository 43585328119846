import { graphql } from "gatsby"
import React from "react"
import { Container } from "reactstrap"
import HeroHeader from "../components/HeroHeader"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { LanguageContext } from "../context/LanguageContext"
import footerRender from "../helpers/footerRender"
import { sectionRender } from "../helpers/sectionRender"

const Main = ({ data }) => {
  const { language } = React.useContext(LanguageContext)

  const translationObject = data.allGraphCmsPage.nodes.find(
    obj => obj.locale === language
  )
  const defaultObject = data.allGraphCmsPage.nodes[0]

  const defaultHeaderData = defaultObject.header
  const translationHeaderData = translationObject.header

  return (
    <Layout>
      <Seo title={translationObject.title} />
      <HeroHeader
        title={translationHeaderData.title}
        description={translationHeaderData.description}
        imageUrl={defaultHeaderData.asset.localFile.publicURL}
        backgroundUrl={defaultHeaderData.backgroundImage.url}
      />
      {sectionRender(translationObject, defaultObject)}
      <div className="w-100">{footerRender(translationObject)}</div>
    </Layout>
  )
}

export const query = graphql`
  query MainPageQuery {
    allGraphCmsPage(filter: { slug: { eq: "main" } }) {
      nodes {
        locale
        title
        displayHeader
        isEvenColored
        header {
          title
          description {
            html
          }
          asset {
            mimeType
            localFile {
              publicURL
            }
            url
          }
          backgroundImage {
            mimeType
            localFile {
              publicURL
            }
            url
          }
          assetPosition
          displayOnlyTitle
          particleEffect
          displayBackground
          titleBackgroundEffect
          linkItems {
            text
            link
            isInternal
            buttonTheme
            outline
            type
            image {
              localFile {
                publicURL
              }
            }
            imagePosition
          }
        }
        pageSections {
          isExternalSource
          externalAssetMimeType
          externalAssetUrl
          sectionId
          title
          subTitle
          titleBackgroundEffect
          textPosition
          type
          isHidden
          imageHaveBackground
          asset {
            url
            localFile {
              publicURL
            }
            mimeType
          }
          carouselSlideTime
          carouselAssets {
            mimeType
            url
            localFile {
              publicURL
            }
          }
          description {
            html
          }
          imagePosition
          linkItems {
            link
            type
            text
            isHidden
            outline
            image {
              localFile {
                publicURL
              }
            }
            imagePosition
          }
          cardItems {
            title
            description {
              html
            }
            asset {
              localFile {
                publicURL
              }
            }
          }
          leftDescription {
            html
          }
          rightDescription {
            html
          }
          imagesWithLink {
            id
            title
            linkItem {
              link
              type
              text
              isHidden
            }
            asset {
              localFile {
                publicURL
              }
            }
          }
          graspTeamMemberGroups {
            id
            title
            subTitle
            graspTeamMembers {
              id
              name
              role
              email
              description {
                html
              }
              photo {
                localFile {
                  publicURL
                }
              }
            }
          }
          logos {
            width
            height
            localFile {
              publicURL
            }
          }
          orderImages {
            localFile {
              publicURL
            }
          }
          numbersWithDescription {
            name
            value
          }
          resizableAssetsWithContent {
            id
            desktopItemWidth
            tabletItemWidth
            mobileItemWidth
            highlighted
            assetWithContent {
              title
              description {
                html
              }
              asset {
                localFile {
                  publicURL
                }
                url
                mimeType
              }
              linkItem {
                text
                link
                isInternal
                buttonTheme
                outline
                type
                image {
                  localFile {
                    publicURL
                  }
                }
                imagePosition
              }
            }
          }
          galleryAssets {
            id
            localFile {
              publicURL
            }
          }
        }
        footerSections {
          title
          subTitle
          newsletter {
            title
            subTitle
            successText
            errorText
            description {
              html
            }
            inputElement {
              buttonText
              label
              slug
              placeholder
            }
          }
          type
        }
      }
    }
  }
`

export default Main
